import React, { useState } from 'react';
import './estilos.css';

const FrmAlbum = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const images = [
    { src: 'foto1.jpeg', descripcion: 'Un día especial en la playa' },
    { src: 'foto2.jpeg', descripcion: 'Sesion de fotos con difraz' },
    { src: 'foto3.jpeg', descripcion: 'Enojada la bibe' },
    { src: 'foto4.jpeg', descripcion: 'Paseando difrazados' },
    { src: 'foto5.jpeg', descripcion: 'Despues de tomar cafecito' },
    { src: 'foto6.jpeg', descripcion: 'Carita ocurrida de la bibe' },
    { src: 'foto7.jpeg', descripcion: 'Besitos a la cumple mes' },
    { src: 'foto8.jpeg', descripcion: 'Su 6to mesesito' },
    { src: 'foto9.jpeg', descripcion: 'Bestio a la bibe' },
    { src: 'foto10.jpeg', descripcion: 'Día de paseo en familia' },
  ];

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = (e) => {
    if (e.target.className === 'modal') {
      setSelectedImage(null);
    }
  };

  return (
    <div className="album-container">
      <h1 className="album-title">
        Nuestra Hermosa pequeña familia
      </h1>
      <div className="photos-grid">
        {images.map((image, index) => (
          <div key={index} className="photo-container">
            <img
              src={require(`./${image.src}`)}
              alt={`Foto ${index + 1}`}
              className="photo"
              onClick={() => handleImageClick(image)}
            />
            <div className="description">
              {image.descripcion}
            </div>
          </div>
        ))}
      </div>

      {selectedImage && (
        <div className="modal" onClick={handleCloseModal}>
          <span
            className="close"
            onClick={() => setSelectedImage(null)}
          >
            &times;
          </span>
          <img
            className="modal-content"
            src={require(`./${selectedImage.src}`)}
            alt={selectedImage.descripcion}
          />
          <div className="caption">
            {selectedImage.descripcion}
          </div>
        </div>
      )}
    </div>
  );
};

export default FrmAlbum;
